<template>
    <div class="koocoding-header">
        <div class="left-side">
            <div class="return" @click.stop="goBack()" v-if="headerConfig.goBack">
                <img src="@/assets/images/ide/return.svg">
            </div>
            <div class="logo" v-if="headerConfig.logo">
                <img src="@/assets/images/common/logo_white_no_text.png">
            </div>
            <div id="project-name" class="project-name" v-if="headerConfig.projName">
                <input type="text" :value="projInfo.name" @change="projectNameChanged" @focus="focusInput($event)">
            </div>

            <div class="nav-tab" v-if="headerConfig.nav">
                <div class="nav-tab-btn" v-bind:class="{ active: current == 'demo' }" @click.stop="switchNav('demo')">
                    <img src="/images/project_new/classroom/tab-icon-1-active.svg" v-if="current == 'demo'">
                    <img src="/images/project_new/classroom/tab-icon-1.svg" v-else>
                    <div class="text">目标</div>
                </div>
                <div class="nav-tab-btn" v-bind:class="{ active: current == 'user' }" @click.stop="switchNav('user')">
                    <img src="/images/project_new/classroom/tab-icon-2-active.svg" v-if="current == 'user'">
                    <img src="/images/project_new/classroom/tab-icon-2.svg" v-else>
                    <div class="text">练习</div>
                </div>
            </div>
            <div class="cmd-btn" @click.stop="toggleShowDemoCode()" v-if="current == 'demo' && headerConfig.demoCode">
                <img src="/images/project_new/classroom/cmd-icon-3.svg">
                <div class="text">示例</div>
            </div>

            <div class="cmd-btn" @click.stop="copyRes()" v-if="headerConfig.copyRes">
                <v-icon size="18" dark class="icon"> mdi-content-copy </v-icon>
                <div class="text">复制素材</div>
            </div>
            <div class="cmd-btn" @click.stop="changeBg()" v-if="headerConfig.changeBg">
                <img src="@/assets/images/common/data.svg">
                <div class="text">背景</div>
            </div>

            <div class="cmd-btn" @click.stop="toggleScreenfull()" v-if="headerConfig.fullscreen">
                <v-icon size="20" dark class="icon"> mdi-arrow-expand-all </v-icon>
                <div class="text">全屏</div>
            </div>
            
            <div class="cmd-btn" @click.stop="toggleData()" v-if="headerConfig.data">
                <img src="@/assets/images/common/data.svg">
                <div class="text">数据</div>
            </div>
            <div class="cmd-btn" @click.stop="switchCode()" v-if="headerConfig.code">
                <img src="/images/project_new/classroom/cmd-icon-code-slash.svg">
                <div class="text">代码</div>
            </div>

            <div class="cmd-btn" @click.stop="help()" v-if="headerConfig.help">
                <v-icon size="20" dark class="icon"> mdi-help-circle-outline </v-icon>
                <div class="text">函数表</div>
            </div>
            <div class="cmd-btn" @click.stop="save()" v-if="headerConfig.save">
                <v-icon size="18" dark class="icon"> mdi-content-save </v-icon>
                <div class="text" v-if="isSaving">保存中</div>
                <div class="text" v-else>保存</div>
            </div>
            <div class="cmd-btn" @click.stop="release()" v-if="headerConfig.release">
                <v-icon size="20" dark class="icon"> mdi-publish </v-icon>
                <div class="text" v-if="isReleasing">发布中</div>
                <div class="text" v-else>发布</div>
            </div>

            <div class="cmd-btn" @click.stop="showExercise()" v-if="headerConfig.exercise">
                <v-icon size="20" dark class="icon"> mdi-calendar-question </v-icon>
                <div class="text">练习</div>
            </div>
            <div class="cmd-btn" @click.stop="reset()" v-if="headerConfig.reset">
                <v-icon size="20" dark class="icon"> mdi-reload </v-icon>
                <div class="text">重置</div>
            </div>
            
            <div class="cmd-btn" @click.stop="fontPlus()" v-if="headerConfig.fontPlus">
                <v-icon size="24" dark class="icon"> mdi-magnify-plus-outline </v-icon>
                <div class="text" style="margin-top: 0;">放大</div>
            </div>
            <div class="cmd-btn" @click.stop="fontMinus()" v-if="headerConfig.fontMinus">
                <v-icon size="24" dark class="icon"> mdi-magnify-minus-outline </v-icon>
                <div class="text" style="margin-top: 0;">缩小</div>
            </div>

            <div class="cmd-btn" @click.stop="createDemo()" v-if="headerConfig.createDemo">
                <v-icon size="20" dark class="icon"> mdi-plus-box </v-icon>
                <div class="text">示例</div>
            </div>
            <div class="ml-2 python-demo" v-if="headerConfig.demoList">
                <div v-for="(id, index) in pyDemoIds"
                    v-bind:key="index"
                >
                    <v-chip class="ma-2" label small 
                        text-color="white" 
                        :color="pyCurDemoId == id ? 'pink' : 'grey darken-1'"
                        @click="showDemo(id, index)" 
                        close 
                        @click:close="delDemo(id, index)"
                        v-if="id != null"
                    >
                        示例 {{ index + 1 }}
                    </v-chip>
                </div>
                <!-- <div v-for="(id, index) in pyDemoIds" v-bind:key="index">
                    <div class="cmd-btn" 
                        @click="showDemo(id, index)"
                        v-bind:key="index"
                        v-if="id != null"
                    >
                        <v-icon size="20" dark> mdi-numeric-{{index + 1}}-circle-outline </v-icon>
                        <div class="text">示例</div>
                    </div>
                </div> -->
            </div>

            <div class="autoSaveTip" v-if="topMsg != ''">
                <img src="@/assets/images/project_new/success.svg">
                <div class="text">{{ topMsg }}</div>
            </div>
        </div>

        <div class="middle-side" v-if="headerConfig.title">
            {{ sectionInfo.name != "" ? sectionInfo.name : projInfo.name }}
        </div>

        <div class="right-side">
            <div class="section" v-if="courseInfo.sections && courseInfo.sections.length > 1 && headerConfig.sectionList">
                <div class="text">当前小节</div>
                <div class="number" 
                    v-for="(section, index) in courseInfo.sections"
                    v-bind:key="index"
                    v-bind:class="{active: section.sid == sid}"
                    @click.stop="goto(section.sid, section.sub_type)">
                    <v-menu
                        open-on-hover
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <div v-if="section.index != 0">
                                    {{ section.index }}
                                </div>
                                <v-icon size="12" dark class="icon" color="#eee" v-else> mdi-triangle </v-icon>
                            </div>
                        </template>
                        <v-card class="mx-auto" max-width="344">
                            <v-card-text class="section-obj">
                                <div class="text--primary name">
                                    {{ section.name }}
                                </div>
                                <div class="text--primary text">
                                    {{ section.desc }}
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </div>
            </div>
            <div class="login" v-if="headerConfig.avatar">
                <div class="avatar" v-if="userInfo.avatar != ''">
                    <img :src="userInfo.avatar">
                </div>
                <div class="profile-avatar" v-else>
                    {{ getTypeFirstLetter(userInfo.name) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    import { mapGetters } from 'vuex'
    import screenfull from 'screenfull';
    import { getTypeFirstLetter } from "@/utils/util";
    import { gotoSection } from '@/utils/common'
    import { saveProjectName } from '@/api/web'
    import { addAdminPythonDemo, delAdminPythonDemo } from '@/api/admin'
    import { goBack } from '@/utils/util'

    export default {
        name: 'Header',
        props: [
            'headerConfig'
        ],
        data: function () {
            return {
                // 多语言
                lang: {
                    "zh-cn": "简体中文",
                    "zh-tw": "繁体中文",
                    "en": "English",
                },
                showCode: false,

                // 正在保存代码
                isSaving: false,
                // 正在发布代码
                isReleasing: false,

                // 定时器
                intervalAutoSave: null,
            }
        },
        computed: {
            ...mapGetters([
                'pid',
                'cid',
                'sid',
                'current',
                'userInfo',
                'courseInfo',
                'projInfo',
                'sectionInfo',
                'topMsg',
                'pyDemoIds',
                'pyCurDemoId',
            ]),
        },
        created() {
        },
        mounted() {
            // 启动定时保存
            // this.intervalAutoSave = setInterval(this.autoSave, 15000);
        },
        beforeDestroy() {
            if (this.intervalAutoSave) {
                clearInterval(this.intervalAutoSave);
            }
        },
        activated() {
        },
        watch: {
        },
        methods: {
            goBack,
            getTypeFirstLetter,
            gotoSection,
            focusInput(event) {
                event.currentTarget.select();
            },
            projectNameChanged(e) {
                const { value } = e.target;
                let projName = value
                saveProjectName({ pid: this.pid, name: projName });
            },

            showExercise() {
                this.$store.commit('web/SetPyCurrent', 'exercise');
                this.$store.commit('web/SetPyCurDemoId', '');

                this.$emit('sendCmd', { cmd: 'showExercise', params: {}});
            },
            showDemo(demoId) {
                if (this.pyCurDemoId != demoId) {
                    this.$store.commit('web/SetPyCurDemoId', demoId);
                    this.$store.commit('web/SetPyCurrent', 'demo');

                    this.$emit('sendCmd', { cmd: 'showDemo', params: {}});
                }
            },
            async createDemo() {
                let id = ""
                if (this.pid) {
                    id = this.pid
                } else if (this.sid) {
                    id = this.sid
                }
                let res = await addAdminPythonDemo(id)
                let newId = res.data

                this.$store.commit('web/AddPythonDemo', newId);
            },
            async delDemo(demoId, index) {
                let id = ""
                if (this.pid) {
                    id = this.pid
                } else if (this.sid) {
                    id = this.sid
                }
                await delAdminPythonDemo({sid: id, demoId: demoId})

                this.$store.commit('web/DelPythonDemo', index);
            },
            goto(sid, subType) {
                this.gotoSection(this.cid, sid, subType, 'classroom', 'replace')
            },
            // 需要跨iframe的命令
            save() {
                this.$emit('sendCmd', { cmd: 'save', params: {}});
            },
            async autoSave() {
                await this.save(false);
            },
            reset() {
                this.$emit('sendCmd', { cmd: 'reset', params: {}});
            },
            release() {
                this.$emit('sendCmd', { cmd: 'release', params: {}});
            },
            help() {
                this.$emit('sendCmd', { cmd: 'help', params: {}});
            },
            fontPlus() {
                this.$emit('sendCmd', { cmd: 'fontPlus', params: {}});
            },
            fontMinus() {
                this.$emit('sendCmd', { cmd: 'fontMinus', params: {}});
            },
            toggleScreenfull() {
                if (screenfull.isEnabled) {
                    screenfull.toggle();
                }
            },
            switchCode() {
                this.showCode = !this.showCode
                this.$emit('sendCmd', { cmd: 'switchCode', params: { showCode: this.showCode }});
            },
            switchNav(nav) {
                if (nav != this.current) {
                    this.$emit('sendCmd', { cmd: 'switchNav', params: { nav: nav }});
                }
            },
            toggleData() {
                this.$emit('sendCmd', { cmd: 'toggleData', params: {}});
            },
            toggleShowDemoCode() {
                this.$emit('sendCmd', { cmd: 'toggleShowDemoCode', params: {}});
            },
            changeBg() {
                $(".blocklyMainBackground").css({fill: 'white'})
            },
            copyRes() {
                this.$emit('sendCmd', { cmd: 'copyRes', params: {}});
            },
        },
        components: {
        }
    }
</script>

<style lang="scss" scoped>
@import '@/views/components/css/header.scss';

.section-obj {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .name {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 3px;
    }
    .text {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        color: #555;
    }
}
</style>

<style lang="scss">
/** 用于调整v-switch中文字的大小和颜色 */
.show-code {
    .v-label {
        font-size: 13px !important;
        color: #eee !important;
    }
    padding: 0 15px;
}
</style>
